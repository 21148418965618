<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="text-white d-inline-block mb-0"></h6>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--8">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-2">
                              <custom-pagination
                              :page-count="table.page.last_page"
                              v-model="table.page.current_page"
                              size="sm"
                              @click.native="changePage(table.page.current_page)"
                              ></custom-pagination>
                        </div>
                        <div class="col-6">
                            <base-input input-classes="form-control-sm text-center border-radius-20" v-model="form.search" :placeholder="tt('search')" v-on:keyup="filterBySearch" :disabled="onLoad.table"/>
                        </div>
                        <div class="col-4">
                            <base-button type="default" size="sm" class="float-right" @click="add">{{ tt('add') }}</base-button>
                        </div>
                    </div>
                </div>
                <div class="mt--4">
                    <el-table ref="tableService" height="400px" :data="table.data" header-row-class-name="thead-light" v-if="!onLoad.table"  highlight-current-row @current-change="handleCurrentChange" :row-class-name="tableRowClassName">
                        <el-table-column :label="tt('abc_criteria')" prop="abc_criteria" min-width="125px" sortable></el-table-column>
                        <el-table-column :label="tt('order_cost')" prop="order_cost" min-width="125px" sortable></el-table-column>
                        <el-table-column :label="tt('holding_cost')" prop="holding_cost" min-width="125px" sortable></el-table-column>
                        <el-table-column :label="tt('z')" prop="z" min-width="125px" sortable></el-table-column>
                        <!-- <el-table-column :label="tt('indicator')" prop="indicator" min-width="125px" sortable></el-table-column> -->
                        <el-table-column prop="action"  width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item :command="{action:'edit', data:row}">{{ tt('edit') }}</el-dropdown-item>
                                        <el-dropdown-item :command="{action:'remove', data:row.id}">{{ tt('delete') }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                    <page-loading v-else />
                </div>
                <div class="card-footer" v-if="!onLoad.table">
                    <div class="row">
                        <div class="col-6 text-left">
                            {{ tt('page_info', {
                                size: (table.page.current_page - 1) * table.page.per_page +
                      table.data.length,
                    total: table.total
                            })}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <validation-observer v-slot="{invalid}">
            <modal :show.sync="form.show">
                <template slot="header">
                    <h5 class="modal-title">{{ form.title }}</h5>
                </template>
                <div>
                    <div  v-if="errorMessage.message !== null">
                        <base-alert type="danger">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}
                                </span>
                            </div>
                        </base-alert>
                    </div>
                    <div>
                        <label for="" class="form-control-label">{{ tt('abc_criteria')}} <span class="text-danger">*</span></label>
                        <base-input type="text" v-model="service.abc_criteria" rules="required" :placeholder=" tt('abc_criteria')" :name=" tt('abc_criteria')"></base-input>
                    </div>
                    <div>
                        <label for="" class="form-control-label">{{ tt('order_cost')}} <span class="text-danger">*</span></label>
                        <base-input type="number" v-model="service.order_cost" rules="required" :placeholder=" tt('order_cost')" :name=" tt('order_cost')"></base-input>
                    </div>
                    <div>
                        <label for="" class="form-control-label">{{ tt('holding_cost')}} <span class="text-danger">*</span></label>    
                        <base-input type="number" v-model="service.holding_cost" rules="required" :placeholder=" tt('holding_cost')" :name=" tt('holding_cost')"></base-input>
                    </div>
                    <div>
                        <label for="" class="form-control-label">{{ tt('z')}} <span class="text-danger">*</span></label>
                        <base-input type="number" v-model="service.z" rules="required" :placeholder=" tt('z')" :name=" tt('z')"></base-input>
                    </div>
                    <div>
                        <label for="" class="form-control-label">{{ tt('indicator')}} <span class="text-danger">*</span></label>
                        <base-input type="text" v-model="service.indicator" rules="required" :placeholder=" tt('indicator')" :name=" tt('indicator')"></base-input>
                    </div>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="close()">{{ tt('close') }}</base-button>
                    <base-button type="primary" @click="save()" :disabled="form.load" v-if="!form.edit">{{ tt('save')}} <span v-if="form.load"><i class="fas fa-spinner fa-spin"></i></span></base-button>
                    <base-button type="primary" @click="update()" :disabled="form.load" v-else>{{ tt('update')}} <span v-if="form.load"><i class="fas fa-spinner fa-spin"></i></span></base-button>
                </template>
            </modal>
        </validation-observer>
    </div>
</template>

<script>
import Api from '@/helpers/api';
import ServiceLevelService from '@/services/rcs/abc/servicelevel.service'
export default {
    data(){
        return{
            table:{
                total:0,
                data:[],
                page:{
                    current_page:1
                }
            },
            onLoad:{
                table:false,
                btnSave:false,
            },
            service:{
                id:null,
                abc_criteria:null,
                order_cost:null,
                holding_cost:null,
                z:null,
                indicator:null,
            },
            form:{
                show:false,
                edit:false,
                title:this.tt('add'), 
                search:null,
                load:false,
            },
            errorMessage:{
                message:null,
                data:[]
            },
            currentRow:null,
        }
    },
    mounted(){
        this.get();
    },
    methods:{
        get(page = null){
            let context = this;
            this.onLoad.table = true;
            if(this.table.page.current_page){
                page = this.table.page.current_page
            }
            Api(context, ServiceLevelService.get(page, {search: this.form.search})).onSuccess((response) => {
                context.table.data = response.data.data.data
                context.table.total = response.data.data.total;
                context.table.page.last_page = response.data.data.last_page;
                context.table.page.current_page = response.data.data.current_page;
                context.table.page.total = response.data.data.total;
                context.table.page.per_page = response.data.data.per_page;
            }).onError((err) =>{
                context.table.data = []
                context.table.total = 0;
            }).onFinish(() =>{
                context.onLoad.table = false
            }).call()
        },
        changePage(page) {
            let context = this;
            this.get(page);
        },
        filterBySearch(){
           setTimeout(() =>{
               this.get()
           },100)
        },
        add(){
            this.form.show = true;
            this.form.edit = false;
            this.form.title = this.tt('add')
        },
        save(){
            let context = this;
            this.form.load = true;
            this.service.id = null;
            Api(context, ServiceLevelService.create_service_level(this.service)).onSuccess((response) =>{
                context.$notify({
                    message:response.data.message,
                    type:'success',
                    verticalAlign:'bottom',
                    horizontalAlign:'left'
                })
                context.form.show = false;
                context.get()
                context.$refs.tableService.setCurrentRow(context.currentRow)
            }).onError((err) =>{
                context.$notify({
                    message: err.response.data.message,                  
                    type: 'danger',
                    verticalAlign: 'bottom', 
                    horizontalAlign: 'left'
                })
                context.errorMessage.data = err.response.data.data
                context.errorMessage.message = err.response.data.message
            }).onFinish(() => {
                context.form.load = false
            }).call()
        },
        update(){
            let context = this;
            this.form.load = true;
            this.confirmDialog(this.tt('confirm_update')).then((result) =>{
                if(result.value){
                    Api(context, ServiceLevelService.update_service_level(this.service)).onSuccess((response) =>{
                        context.$notify({
                            message:response.data.message,
                            type:'success',
                            verticalAlign:'bottom',
                            horizontalAlign:'left'
                        })
                        context.form.show = false;
                        context.get()
                    }).onError((err) =>{
                        context.$notify({
                            message: err.response.data.message,                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        })
                        context.errorMessage.data = err.response.data.data
                        context.errorMessage.message = err.response.data.message
                    }).onFinish(() => {
                        context.form.load = false
                    }).call()
                }else{
                    this.form.load = false
                }
            })
        },
        edit(data){
            this.service.id = data.id
            this.service.abc_criteria = data.abc_criteria;
            this.service.order_cost = data.order_cost;
            this.service.holding_cost = data.holding_cost;
            this.service.z = data.z
            this.service.indicator = data.indicator;
            this.form.edit = true;
            this.form.show = true;
            this.form.title = this.tt('update')
        },
        remove(data){
            let context = this;
            this.onLoad.table = true;
            this.confirmDialog(this.tt('confirm_delete')).then((result) =>{
                if(result.value){
                    Api(context, ServiceLevelService.delete_service_level(data)).onSuccess((response) =>{
                        context.$notify({
                            message: response.data.message,
                            type: "success",
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                        context.get();
                    }).onError((err) =>{
                        context.$notify({
                            message: err.response.data.message,
                            type: "danger",
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                    }).call()
                }else{
                    this.onLoad.table = false
                }
            })
        },
        close(){
            this.form.show = false;
            this.errorMessage.data = [];
            this.errorMessage.message = null
        },
        handleTableAction(command) {
            switch (command.action) {
                case 'edit':
                        this.edit(command.data);
                    break;
                case 'remove':
                        this.remove(command.data);
                    break;
                default:
                    break;
            }
        },
        tableRowClassName({ row, rowIndex }) {
            if (this.currentRow != null) {
                if (this.currentRow.id == row.id) {
                    return "current-row";
                }
            }
        },
        handleCurrentChange(val){
            this.currentRow = val
        }
    }
}
</script>

<style>
.el-table__body tr.current-row > td {
  background-color: #ffe391 !important;
}
</style>